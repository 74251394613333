
function Slider(autostart) {
    this.autostart = autostart || false;
    this.activeSlide = 0;
};

Slider.prototype.start = function() {
    var slidesNumber = $(".b-slideshow-item").size();
    var activeSlide = this.activeSlide;

    $(".b-slideshow-item").eq(this.activeSlide).addClass("view");
    $('.b-slideshow').attr('active-slide', this.activeSlide);

    if (this.autostart) {
        //this.runAutoStart();
    }

    $(".b-click").click(function(){
        if ($(this).hasClass('prev')) {
            if (activeSlide <= 0) {
                activeSlide = slidesNumber - 1;
            } else {
                activeSlide--;
            }
        } else {
            if (activeSlide >= slidesNumber - 1) {
                activeSlide = 0;
            } else {
                activeSlide++;
            }
        }
        $(".b-slideshow-list .view").fadeOut(1000).removeClass("view");

        $("#slide-" + activeSlide + "-image").fadeIn(1000).addClass("view");
        $('.b-slideshow').attr('active-slide', activeSlide);
    });
};


Slider.prototype.runAutoStart = function() {
    var slidesNumber = $(".b-slideshow-item").size();
    var pause = false;
    setInterval(function () {
        if (pause==false) {
            if (this.activeSlide >= slidesNumber-1){
                this.activeSlide = 0;
            }
            else {
                this.activeSlide++;
            }
            $(".b-click.next").click();
        }
    }, 10000);
};
