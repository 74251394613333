(function ($) {
    $(document).ready(function () {
      var slider = new Slider(true);
      slider.start();
      slider.runAutoStart();

      var selectorImagePopup = '.attachments img';
      ImagePopup(selectorImagePopup);

      var feedbackPopups = new FeedbackPopup('.push-form');
      feedbackPopups.syncOrderPopup('.submit-order');

      $('.attachments .overlay').click(function(e) {
          $(this).next().click();
      });

        if ($('.errors-layout .alert').length) {
            $('.errors-layout').fadeOut(6000);
        }

        $('.dropdown-toggle').click(function() {
            $('.dropdown-menu').toggleClass('activate');
        });

    });


})(jQuery);
