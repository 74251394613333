

function FeedbackPopup(selector) {
    this.initFeedback(selector);
};

FeedbackPopup.prototype.initFeedback = function(selector) {
    var overlay =  ("<div class='feedback-modal-overlay feedback-js-modal-close'></div>");
    $("body").append(overlay);
    var popup =  ('<div id="dialog-feedback" class="feedback-modal-box">' +
    '<header> <a href="javascript:void(0);" class="feedback-js-modal-close close">×</a>' +
    '</header> <div class="modal-body"></div></div>');
    $("body").append(popup);
    var self = this;
    $(selector).click(function(e) {
        e.preventDefault();
        self.send();
    });

    $(".feedback-js-modal-close, .feedback-modal-overlay").on( "click", function() {
        $(".feedback-modal-box, .feedback-modal-overlay").fadeOut(500);
    });
};

FeedbackPopup.prototype.syncOrderPopup = function(selector) {
    var self = this;
    $(selector).click(function(e) {
        e.preventDefault();
        var door_id = $(this).attr('data-door-id');
        self.send(door_id);
    });

    $(".feedback-js-modal-close, .feedback-modal-overlay").on( "click", function() {
        $(".feedback-modal-box, .feedback-modal-overlay").fadeOut(500);
        window.scroll = null;
    });
};

FeedbackPopup.prototype.send = function(door_id) {
    door_id = door_id || 0;
    $.get('/feedback',{door_id: door_id},function(data,textStatus,jqXHR)
    {
        if (textStatus == 'success') {
            $(".feedback-modal-overlay").fadeTo(500, 0.7);
            $('#dialog-feedback').fadeIn(300);

            $('#dialog-feedback .modal-body').html(data);
            updateModalBoxPosition();
            FeedbackPopup.prototype.checkSupportedPositionFixedCSS();
        }
    });
    $(window).resize(function() {
        updateModalBoxPosition();
    });

    function updateModalBoxPosition(){
        var outerHeight = window.outerHeight || $(window).height();
        $(".feedback-modal-box").css({
            top: (outerHeight - $(".feedback-modal-box").outerHeight()) / 2,
            left: ($(window).width() - $(".feedback-modal-box").outerWidth()) / 2
        });
    }
};

FeedbackPopup.prototype.checkSupportedPositionFixedCSS = function(selector) {
    var selector = ".feedback-modal-box";
    var innerHeightBox = $(selector).height();
    var innerWidthBox = $(selector).width();
    var isSupported = null;
    if (navigator.platform == 'iPad' || navigator.platform == 'iPhone' || navigator.platform == 'iPod' || navigator.platform == 'Linux armv6l')
    {
        window.onscroll = function()
        {
            var iPadPositionY = (window.innerHeight / 2) + window.pageYOffset-(innerHeightBox / 2);
            var iPadPositionX = (window.innerWidth / 2) + window.pageXOffset-(innerWidthBox / 2);
            $(selector).css("position", "absolute");

            $(".feedback-modal-box").css({
                top: iPadPositionY,
                left: iPadPositionX
            });

            if ($(selector).css("display") == 'none') {
                window.onscroll = null;
            }
        }
    }
    return isSupported;
};

