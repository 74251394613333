
function ImagePopup(selector) {
    var appendthis =  ("<div class='modal-overlay js-modal-close'></div>");
    $(selector).click(function(e) {
        e.preventDefault();
        $("body").append(appendthis);
        $(".modal-overlay").fadeTo(500, 0.7);
        var modalBox = $(this).attr('data-modal-id');
        $('#'+modalBox).fadeIn(300);

        var img = document.createElement('img');
        img.onload = img.onerror = updateModalBoxPosition($(this).attr('dialog-height'), $(this).attr('dialog-width'));
        img.src = $(this).attr('dialog-url');
        console.log(selector);
        
        $('#' + modalBox + ' .modal-body').html('<img src="' + $(this).attr('dialog-url') + '" />');
    });

    $('body').on('click', '.modal-overlay, .js-modal-close', function () {
        $(".modal-box, .modal-overlay").fadeOut(500, function() {
            $(".modal-overlay").remove();
        });
    });

    $(window).resize(function() {
        updateModalBoxPosition();
    });
};

function updateModalBoxPosition(outerHeight, outerWidth){
    $(".modal-box").css({
        top: ($(window).height() -  parseInt(outerHeight)) / 2,
        left: ($(window).width() - parseInt(outerWidth)) / 2
    });
}
